import axios from 'axios';
import store from "../store"
import apiUrl from './api-url'

const ACCESS_KEY = 'accessToken'
const REFRESH_KEY = 'refreshToken'

class AuthService {

  constructor() {

    let that = this;

    this.axiosApiInstance = axios.create();
    // Request interceptor for API calls - this inserts the auth header into all HTTP requests using this axios instance
    this.axiosApiInstance.interceptors.request.use(
      async config => {
        config.headers = this.authHeader()
        return config;
      },
      error => {
        Promise.reject(error)
      });

    // Response interceptor for API calls - this checks the response from every HTTP request and handles invalidated tokens
    this.axiosApiInstance.interceptors.response.use((response) => {
      return response
    }, async function (error) {
      //Make a copy of the request - we'll try again after we've tried to obtain a new access token
      const originalRequest = error.config;
      // First we check if this is the first time we've hit a 401 - likely an invalid token TODO: Actually check properly
      if (error.response.status === 401 && !originalRequest._retry) {

        // Flag the request as being retried
        originalRequest._retry = true;

        // Request a new access token via the refresh token
        await that.refresh()

        // Try the request again - hopefully it works as we should have a new access token
        return that.axiosApiInstance.request(originalRequest)
      }
      else if (error.response.status === 401 && originalRequest._retry) {
        // We've got another 401 and we've already tried to obtain a new token, so just log the user out and get them to log in again

        store.dispatch("auth/logout");

        // Ensure refresh to get site settings.
        window.location.href = "/login"
      }
      return Promise.reject(error);
    });
  }

  authHeader() {
    let access = localStorage.getItem(ACCESS_KEY);
    if (access) {
      return {
        Authorization: 'Bearer ' + access, 'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      }; // for DRF Backend
    } else {
      return {};
    }
  }

  login(user) {
    // Use a post request to obtain a new access token using a username and password
    return axios
      .post(apiUrl() + 'api-token/obtain/', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.access && response.data.refresh) {
          //Save tokens to local storage if they've been returned
          localStorage.setItem(ACCESS_KEY, response.data.access)
          localStorage.setItem(REFRESH_KEY, response.data.refresh)
        }
        return response.data;
      });
  }

  logout() {
    // Remove tokens from local storage
    localStorage.removeItem(ACCESS_KEY)
    localStorage.removeItem(REFRESH_KEY)
  }

  refresh() {
    // We need to get a refresh key from local storage and ask for a new access token
    let refreshToken = localStorage.getItem(REFRESH_KEY)
    return axios
      .post(apiUrl() + 'api-token/refresh/', {
        refresh: refreshToken
      })
      .then(response => {
        if (response.data.access) {
          // Store the new access token in local storage
          localStorage.setItem(ACCESS_KEY, response.data.access);
        }
        return response.data;
      })
      .catch(error => {
        return error.data
      });
  }

  register(user) {
    return axios.post(apiUrl() + 'api-token/signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }

  isLoggedIn() {
    if (localStorage.getItem(ACCESS_KEY))
      return true
    return false
  }
}

export default new AuthService();
